/* Instanciamos plugins */

//Smooth scroll para links menu
var easeInCubic = new SmoothScroll('[data-easing="easeInCubic"]', {easing: 'easeInCubic'});

//Animations on scroll
AOS.init();

/**
 * Carousel Projects Home   
 */
$(document).ready(function(){
    $('.projects-carousel').owlCarousel({
        loop:false,
        margin:70,
        responsiveClass:true,
        //autoHeight: true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            992:{
                items:2,
                nav:false,
                nav: true,
            }
        }
    });
});

/**
 * Slide Projects info
 */
$('.btn-link').click(function(e){
    e.stopPropagation();
    $(this).children().toggleClass('fa-caret-up fa-caret-down');
    var $thumbnail = $(this).parent().parent().find('.projects-item-thumbnail');
    $thumbnail.slideToggle('slow', function(){
        $thumbnail.next().slideToggle('slow');
    });
});

